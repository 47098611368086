@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.border-none {
  border: none;
}

/* Add this CSS to your main CSS file or within a style tag in your component */

@media print {
  /* Hide the date, tab's title, and URL */
  @page {
    margin: 0;
  }

  /* body {
    -webkit-print-color-adjust: exact;
    margin: 0;
  } */

  /* Hides the header, footer, and elements showing the URL and date */
  header,
  footer {
    display: none;
  }

  /* Hide elements that you want to exclude from printing */
  .no-print {
    display: none;
  }
}
