.simpleLoader {
    position: fixed;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 50%;
    left: 57%;
    transform: translate(-50%, -50%);
    z-index: 999;
    height: 100%;
    width: 100%;
}

.styledLoader {
    position: fixed;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 71, 79, 0.1);
}